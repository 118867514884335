<template>
  <section>
    <b-card>
      <div class="text-right">
        <feather-icon
          v-if="canEdit"
          v-b-modal.share-modal
          icon="Share2Icon"
          :title="$t('share.tooltip')"
          size="20"
          class="text-primary share-icon"
        />
      </div>
      <template #title>
        <div class="align-items-center d-flex">
          <h4 class="m-0 text-dark">
            {{ translatedType || widgetTitle || $t('organizations.title') }}
          </h4>
        </div>
      </template>
      <organizations-paginated-table
        :isForBackoffice=false
        :type-key="typeKey"
        :per-page="20"
        :listing-type="0"
        :show-filter=true
        :showFilter="true"
      />

      <!-- Share organization -->
      <b-modal
        id="share-modal"
        :title="$t('share.title')"
        no-close-on-backdrop
        @ok="share"
      >
        <template #modal-ok>
          <feather-icon icon="Share2Icon" size="14" />
          {{ $t('share.title') }}
        </template>
        <p>{{ $t('share.select-multiple-organizations') }}:</p>
        <members-select
          v-model="organizationsSelected"
          class="my-1"
          searchable
          :multiple="false"
          :placeholder="$t('form.select-multiple-placeholder')"
          dir="ltr"
          input-id="profile-organizations"
          :fetch-data="fetchOrganizationsPage"
          :has-code="true"
          :code="organizationsFilter"
          label="name"
        />
        <p>{{ $t('share.select-multiple-communities') }}:</p>
        <ajax-select
          id="communities"
          v-model="communitiesSelected"
          class="style-chooser mt-1"
          clearable
          searchable
          multiple
          dir="ltr"
          input-id="shared-communities"
          :placeholder="$t('shared-communities.modal-placeholder')"
          :fetch-data="fetchCommunities"
          label="name"
          :has-code="true"
          :code="communitiesFilter"
        />
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import OrganizationsPaginatedTable from '@/views/apps/organization/components/OrganizationsPaginatedTable.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import { MainType } from '@copernicsw/community-common';

export default {
  name: 'OrganizationsList',
  components: {
    OrganizationsPaginatedTable,
    AjaxSelect,
    MembersSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    typeKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      communitiesSelected: [],
      allCommunities: [],
      organizationsSelected: [],
      isLoading: false,
      currentType: '',
      currentPage: 1,
    };
  },
  computed: {
    storedApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].organizations;
    },
    widgetTitle() {
      return translateTranslationTable(this.$store.getters.currentLocale, this.computedAppNames);
    },
    computedAppNames() {
      return this.storedApp?.customization?.customizationName || this.storedApp?.customization?.null || this.storedApp?.name;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    isMain() {
      return Object.values(this.$store.getters.mainCollective).length === 0;
    },
    type() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
    listingType() {
      return 0;
    },
    translatedType() {
      if (this.type !== null) {
        return translateTranslationTable(
          this.$store.getters.currentLocale,
          typeof this.type === 'string' ? this.type : this.type.namePlural,
        );
      }
      return null;
    },
    canEdit() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  watch: {
    translatedType(translatedType) {
      this.updateBreadcrumbs(translatedType);
    },
    typeKey() {
      this.$store.commit('app/SET_BREADCRUMBS', null);
    },
  },
  async created() {
    this.isLoading = true;
    // this.fetchCommunities();
    this.fetchOrganizationsPage({ page: 1 });
    this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
    this.updateBreadcrumbs(this.translatedType);
    this.isLoading = false;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    updateBreadcrumbs(translatedType) {
      let breadcrumbs;
      if (this.type) {
        breadcrumbs = [
          {
            text: 'organizations.breadcrumb-text.organizations-list',
            to: { name: 'organizations' },
          },
          {
            text: translatedType,
            active: true,
          },
        ];
      } else {
        breadcrumbs = [
          {
            text: 'organizations.breadcrumb-text.organizations-list',
            active: true,
          },
        ];
      }
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async fetchCommunities(page, searchString = '', force = false) {
      return this.$store.dispatch('getItems', {
        itemType: 'communities/wholeBranch',
        customName: 'sharedCommunities',
        page,
        perPage: this.perPage,
        forceAPICall: force,
        storedKey: 'allCommunities',
        requestConfig: {
          searchString: searchString || '',
          showMe: true,
          mainTypeIDs: [6, 7, MainType.Event, MainType.Course, MainType.Project, MainType.Organization, MainType.Challenge, MainType.Service]
        },
      });
    },
    async share() {
      try {
        await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'communities/share',
            customName: 'sharedCommunities',
            storedKey: 'sharedCommunities',
            requestConfig: {
              sharedCommunityKey: this.communitiesSelected,
              communitiesKey: this.organizationsSelected,
            },
          },
        });
        this.notifySuccess(this.$t('share.notify-success'));
        this.communitiesSelected = [];
        this.organizationsSelected = [];
      } catch (error) {
        this.notifyError(this.$t('share.notify-error'));
      }
    },
    async fetchOrganizationsPage({
      page = 1, searchString = '', searchCountry = '', force = true,
    }) {
      this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      const requestConfigOrganizations = {
        isOrganization: true,
        communityParentSlug: this.communitySlug,
      };
      const requestConfigSocieties = {
        listingType: this.listingType,
        orderByName: 1,
        ...(this.selectedCustomFieldsForFilter?.length > 0 ? { classifiers: this.selectedCustomFieldsForFilter } : ''),
        typeKey: this.currentType !== this.communitySlug ? this.currentType : undefined,
      };
      return this.$store.dispatch('getItems', {
        page: page || 1,
        itemType: this.societies ? 'organizations' : 'communities/simply',
        customName: 'shareOrganizations',
        storedKey: this.currentType.key > 0 ? this.currentType.name : this.societies ? 'societies' : 'organizations',
        forceAPICall: force,
        searchString: searchString || '',
        searchCountry: searchCountry || '',
        perPage: this.perPage,
        requestConfig: this.societies ? requestConfigSocieties : requestConfigOrganizations,
      });
    },
    organizationsFilter(response) {
      const organizationOptions = [];
      if (response.length > 0) {
        response.forEach((organization) => organization && organizationOptions.push({
          code: organization.key,
          name: organization.name,
        }));
      }
      return organizationOptions;
    },
    communitiesFilter(response) {
      const communityOptions = [];
      response.forEach((community) => community && communityOptions.push({
        code: community.key,
        name: this.translate(community.name),
      }));
      return communityOptions;
    },
  },
};
</script>
<style lang="scss" scoped>
.share-icon {
  margin-top: -130px;
  cursor: pointer;
}
</style>
